<template>
  <div id="app">
    <h2 id="userDetails">Hello, Unknown..</h2>
    <h1>studious-design.co.uk</h1>
    <div id="blurb">
      <h3>Welcome to my Vue.js learning platform! This site serves as a hands-on project to deepen my understanding and skills in various cutting-edge technologies. Here, I explore the integration of Vue.js with Azure Services, focusing on practical implementations like deploying static web apps, setting up custom domains, and utilizing Azure Functions. My goal is to not only build a functional web application but to also grasp the intricacies of cloud services, continuous deployment, and serverless computing. </h3>
    </div>
  </div>
</template>

<script>
(async function (){
  let response = await fetch(`api/userDetails?name=tonnessen`);
  let message = await response.json();
  document.querySelector("#userDetails").innerHTML = "Hello, " + message.text;
})();

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  background-color: #1d1d1d; /* Dark grey background */
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2b2b2b; /* Light grey text color */
  margin-top: 60px;
  background-color: #1d1d1d; /* Dark grey background */
  height: 100vh; /* Ensure the background covers the full viewport height */
}
h1 {
  color: #2b2b2b; /* Light grey text color */
}
#blurb {
  color: #3a3a3a; /* Light grey text color */
}
</style>
